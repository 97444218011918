import responseService from "../../Services/responseService";
import config from "../../config";
import UserClass from "./UserClass";


class UserState {

    async getAllUsers(){
        const result = await responseService.responseApiServer(config.URL_GET_ALL_USERS)
        return result
    }

    async removeUser(userId){
        const result = await responseService.responseApiServer(config.URL_REMOVE_USER, {id:userId})
        return result
    }

    async createUser(user){
        const result = await responseService.responseApiServer(config.URL_CREATE_USER, {user})
        return result
    }

    async updateUser(user){
        const result = await responseService.responseApiServer(config.URL_UPDATE_USER, {user})
        return result
    }

    async changePassword(id, password){
        const result = await responseService.responseApiServer(config.URL_CHANGE_USER_PASSWORD, {id, password})
        return result
    }

    getEmptyUser(){
        return new UserClass()
    }


}


const u =  new UserState()
export default u;

