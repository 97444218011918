import config from "../config";
import Cookies from 'js-cookie';

class ResponseService{
   async responseApiServer(to, data={}){
        try{
            const token = Cookies.get('token')

            data.token = token
            const response = await fetch(config.URL_API+to,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(data)
            })
            const result = await response.json()
            console.log('result',result)
            if(result && result.badToken){
                Cookies.remove('token')
                window.location.replace( window.location.origin)
            }
            if(result && result.badPage){
                window.location.replace( window.location.origin)
            }
            return result
        }catch (e) {
            return {warning:true, message:'Нет доступа к серверу'}
        }
   }
}

const responseService = new ResponseService()
export default responseService