import './App.css';
import Cookies from "js-cookie";
import AuthPage from "./Pages/AuthPage/AuthPage";
import GamePage from "./Pages/GamePage/GamePage";
// import patternImg from './resources/pattern1.png'
import patternImg from './resources/patterns/smile3_pattern.png'
import GradeUserPage from "./Pages/GradeUserPage/GradeUserPage";

function App() {
   const token = Cookies.get('token')
    // const gameId = Cookies.get('game_id')
    // const userId = Cookies.get('user_id')

    const grade = (new URLSearchParams(window.location.search)).get("grade")

    if (grade){
        return (
            <div  style={{ backgroundImage: `url(${patternImg})`,minHeight:'100vh',backgroundSize:'220px',
                backgroundRepeat: "repeat" }}>
                <div className={'header'}><span>VityazGroup</span></div>
                    <GradeUserPage gameId={grade}/>
            </div>

        )
    }
  if(token){
    return (
        <div style={{ backgroundImage: `url(${patternImg})`,minHeight:'100vh',backgroundSize:'220px',
            backgroundRepeat: "repeat" }}>
            <div className={'header'}><span>VityazGroup</span></div>
            <GamePage/>
        </div>
    )
  }
      return (
          <div  style={{ backgroundImage: `url(${patternImg})`,minHeight:'100vh',backgroundSize:'220px',
              backgroundRepeat: "repeat" }}>
              <div className={'header'}><span>VityazGroup</span></div>
              <AuthPage/>
          </div>

      );
}

export default App;
