import React, {useEffect, useState} from 'react';
import AdminPlacePage from "../../AdminPage/AdminPlacePage";
import s from './AdminGamePage.module.css'

import settingSvg from '../../../resources/settings.svg'
import playSvg from '../../../resources/exit.svg'
import exitSvg from '../../../resources/exit.svg'

const AdminGamePage = ({close}) => {
       return (
            <>
                {/*<div onClick={()=>setIsSettingView(false)} className={'btn_remove'}>Закрыть настройки</div>*/}
                <div className={s.icon_wrapper}>
                    <img onClick={()=>window.location =window.location.origin } className={s.setting_play} src={playSvg} alt=""/>
                </div>
                <AdminPlacePage />
            </>
        )
};

export default AdminGamePage;