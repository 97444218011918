import React, {useState} from 'react';
import NavbarAdmin from "./Components/NavbarAdmin";
import ConstructorComponent from "./Components/ConstructorComponents/ConstructorComponent";

const AdminPlacePage = () => {
    const [page, setPage] = useState('constructor')
    const navPagesList  = [
        {page:'constructor', name:'конструктор'},
        {page:'game', name:'игра'}
    ]
    switch (page){
        case 'constructor':
            return ( <ConstructorComponent/>
            );
        case 'game':
            return (
                <div>
                    <NavbarAdmin page={page} setPage={setPage} navList={navPagesList}/>
                </div>
            );
       
    }


};

export default AdminPlacePage;