import React from 'react';
import s from './GameInfo.module.css'
import QRCode from "qrcode.react";

const GameInfo = ({game, startGame}) => {

    const downloadQRCode = (game) => {
        const qrCodeURL = document.getElementById('qrCodeEl_'+game.id)
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = `${game.name}_QR.png`
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }

    return (
        <div className={s.wrapper}>
            <div className={s.title_wrapper}>
                <div className={s.name}>{game.name}</div>
                <span>Количество этапов: {game.locations.length}</span>
            </div>

                <div className={s.qr_wrapper}>
                    <QRCode
                        key={"qrCodeEl_"+game.id}
                        id={"qrCodeEl_"+game.id}
                        size={130}
                        value={`${window.location.origin}/?grade=${game.id}`}
                    />
                    <div></div>

                </div>
            <div>
                <div className={s.btn_wrapper}>
                    <div onClick={startGame} className={'btn_update'}>Войти</div>
                    <div onClick={()=>downloadQRCode(game)} className={'btn_add'}>Скачать QR</div>
                </div>

            </div>
            <div>{game.description}</div>
        </div>
    );
};

export default GameInfo;