import React, {useState} from 'react';
import s from './AgeAndGenderComponent.module.css'
import Picker from 'react-mobile-picker';
// import { GenderInput } from 'react-gender-input';


const AgeAndGenderComponent = ({setAgeAndGender}) => {
    const [age, setAge] = useState('Возраст')
    const [gender, setGender] = useState('Выберите пол')
    const [isError, setIsError] = useState(false)

    const optionGroups =  {
            gender:{gender: ['Выберите пол','Мужской', "Женский"]},
            age: {age:['Возраст', '10-20', '20-30', '30-40', '40-50',
                    '50-60', '60+']}
    }
    return (
        <div className={s.wrapper}>
            {/*Выбирете ваш пол*/}
            {/*<div className={s.wrapper_gender_btn}>*/}
            {/*    <div>Мужской</div>*/}
            {/*    <div>Женский</div>*/}
            {/*</div>*/}
            {/*<div>Возраст</div>*/}
            <Picker
                optionGroups={optionGroups.gender}
                valueGroups={{
                    gender
                }}
                onChange={(e,value)=>{
                    if (e === 'age'){
                        setAge(value)
                    }else{
                        setGender(value)
                    }
                }
                } />
            {isError && gender==='Выберите пол'?
                <div className={s.error_message}>Не выбран пол</div>:<></>}

            <Picker
                optionGroups={optionGroups.age}
                valueGroups={{
                    gender,age
                }}
                onChange={(e,value)=>{
                    // console.log(e,value)
                    if (e === 'age'){
                       setAge(value)
                    }else{
                        setGender(value)
                    }
                }
            } />
            {isError && age==='Возраст'?
                <div className={s.error_message}>Не выбран возраст</div>:<></>}
            <div className={s.wrapper_btn}>
                <div className={'btn_save'} onClick={()=> {
                    if (gender==='Выберите пол' || age==='Возраст'){
                        setIsError(true)
                    }
                    else{
                        setAgeAndGender(age, gender)
                    }
                }}>Отправить</div>
            </div>

        </div>
    );
};

export default AgeAndGenderComponent;