import React, { useEffect, useState } from 'react';
import GameState from "../../../../../State/GameState/GameState";
import GameCard from "./GameCard/GameCard";
import RedactGameFrom from "./RedactGameFrom/RedactGameFrom";
import s from './RedactGameComponent.module.css'

const RedactGameComponent = () => {
    const [games, setGames] = useState([])
    const [redactGame, setRedactGame] = useState(null)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isRedactFormView, setIsRedactFormView] = useState(false)
    const [locations, setLocations] = useState([])

    const standartLocatios = [
        "Радость",
        "Гордость",
        "Восторг",
        "Удивление",
        "Интерес-возбуждение",
        "Страх",
        "Грусть",
    ]

    const addLocation = () => {
        const game = { ...redactGame }
        game.locations.push({ question: '', locations: [''] })
        setRedactGame(game)
    }

    const addQuestion = (indexLocation) => {
        const game = { ...redactGame }
        game.locations[indexLocation].locations.push('')
        setRedactGame(game)
    }


    const addStandatrQuestions = (indexLocation) => {
        const game = { ...redactGame }
        game.locations[indexLocation].locations = standartLocatios
        setRedactGame(game)
    }

    const removeLocation = (index) => {
        const game = { ...redactGame }
        // game.locations = game.locations.filter((loc, i)=>i!==index)
        delete (game.locations[index])
        setRedactGame(game)
    }

    const removeQuestion = (indexLocation, indexQuestion) => {
        const game = { ...redactGame }
        delete (game.locations[indexLocation].locations[indexQuestion])
        setRedactGame(game)
    }
    const addStandartQuestion = (indexLocation) => {
        const game = { ...redactGame }
        game.locations[indexLocation].question = 'Ваше настроение сегодня можно описать как:'
        setRedactGame(game)
    }

    const setValueFieldGame = (fieldName, indexLocation, value) => {
        const game = { ...redactGame }
        switch (fieldName) {

            case 'locations':
                game[fieldName][indexLocation].locations[value.number] = value.text
                break

            case 'question':
                game.locations[indexLocation].question = value.text
                break
            default:
                game[fieldName] = value
        }
        setRedactGame(game)
    }

    const removeGame = async (id) => {
        const result = await GameState.removeGame(id)
        if (result.warning) {
            setIsError(true)
            setErrorMessage(result.message)
            return false
        } else {
            setTimeout(() => {
                const newGameList = games.filter(g => g.id !== id)
                setGames(newGameList)
            }, 1000)

            return true
        }
    }
    const saveGame = async () => {
        const isNew = !redactGame.id
        const locationsList = []
        redactGame.locations.forEach(loc => {
            if (loc.locations.length > 0) {
                const newLoc = { question: loc.question }
                newLoc.locations = loc.locations.filter(q => q && q !== '')
                console.log('newLoc', newLoc)
                if (newLoc.locations.length > 0)
                    locationsList.push(newLoc)
            }

        })
        redactGame.locations = locationsList
        if (isNew) {
            const result = await GameState.createGame(redactGame)
            if (result.warning) {
                setIsError(true)
                setErrorMessage(result.message)

            } else {
                const newGameList = [...games]
                newGameList.push(result.data.game)
                setGames(newGameList)
                setRedactGame(result.data.game)
            }
            return result
        } else {
            const result = await GameState.updateGame(redactGame)
            if (result.warning) {
                setIsError(true)
                setErrorMessage(result.message)
            } else {
                const newGameList = []
                games.forEach(game => {
                    if (game.id === redactGame.id)
                        newGameList.push(result.data.game)
                    else
                        newGameList.push(game)
                })
                setGames(newGameList)

            }
            return result
        }
    }

    const init = async () => {
        const result = await GameState.getAllLGame()
        if (result.warning) {
            setErrorMessage(result.message)
            setIsError(true)
        } else {
            setGames(result.data.games)
        }
    }

    useEffect(() => {
        init()
    }, [])
    if (isRedactFormView)
        return (
            <div>
                <RedactGameFrom game={redactGame}
                    addStandatrQuestions={addStandatrQuestions}
                    removeQuestion={removeQuestion}
                    save={saveGame}
                    addQuestion={addQuestion}
                    locations={locations}
                    removeLocation={removeLocation}
                    addStandartQuestion={addStandartQuestion}
                    setValueField={setValueFieldGame}
                    addLocation={addLocation}
                    close={() => setIsRedactFormView(false)} />
            </div>
        )
    else
        return (
            <div className={s.wrapper}>
                <div className={s.title}>Список опросников</div>
                {
                    games.map(game =>
                        <GameCard key={'game_list_' + game.id}
                            redact={() => {
                                setRedactGame(game)
                                setIsRedactFormView(true)
                            }}
                            remove={removeGame}
                            game={game} />
                    )
                }
                <div className={s.wrapper_button}>
                    <div onClick={() => {
                        setRedactGame(GameState.getEmptyGame())
                        setIsRedactFormView(true)
                    }} className={'btn_add'}>Добавить опросник</div>
                </div>
            </div>
        );
};

export default RedactGameComponent;