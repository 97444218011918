import * as FileSaver from 'file-saver'
import React from 'react';
import * as XLSX from 'xlsx';

const ExcelExports = ({exportData, fileName}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <div className={'btn_save'} onClick={(e) => exportToCSV(exportData,fileName)}>Сохранить в Excel</div>
    )
};

export default ExcelExports;