
class GameClass{
    name = ''
    locations = [
        {
            question:'',
            locations:['']
        }
    ]
    isUserInfo = false
    isActive = true
}

export default GameClass
