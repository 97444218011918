import React, { useState } from 'react';
import s from './RedactGameFrom.module.css'

const RedactGameFrom = ({ game, save, setValueField, close, addLocation, removeLocation, addQuestion, addStandartQuestion, removeQuestion, addStandatrQuestions }) => {

    const [isViewMessage, setIsViewMessage] = useState(false)
    const [message, setMessage] = useState('')

    return (
        <div className={s.wrapper}>
            <div className={s.title}>{game.id ? 'Редактирование опросника' : 'Создание опросника'}</div>

            <div className={s.wrapper_game}>
                <span className={s.title_name}>Название </span>
                <input className={s.input_box} type="text" value={game.name} placeholder={'название'} onChange={(e) => { setValueField('name', 0, e.target.value) }} />
            </div>
            <div className={s.wrapper_game}>
                <span className={s.title_name}>Запрашивать пользователя возраст и пол</span>
                <input className={s.check_box} type="checkbox" value={game.isUserInfo} placeholder={'название'} onChange={(e) => { setValueField('isUserInfo', 0, e.target.checked) }} />
            </div>
            <div className={s.title_element}>Список этапов</div>
            {game.locations.length > 0 ?
                game.locations.map((loc, iLoc) => <div key={loc.number ? 'loc' + loc.number : 'new' + iLoc}>
                    <div className={s.wrapper_element}>
                        <div>Этап {iLoc + 1}</div>
                        <br />
                        <span>{'Вопрос'}.</span>
                        <textarea value={loc.question} placeholder={'текст вопроса'}
                            onChange={(e) => { setValueField('question', iLoc, { text: e.target.value }) }}
                        />
                        <div className={'btn_update'} onClick={() => addStandartQuestion(iLoc)}>Стандартный вопрос</div>
                        <br />
                        <div>Критерии</div>
                        {loc.locations.map((question, i) => {
                            return <>
                                <tr />
                                <div className={s.wrapper_questions}>
                                    <span className={s.title_question}>{i + 1}.</span>
                                    <textarea value={question} placeholder={'критерий'}
                                        onChange={(e) => { setValueField('locations', iLoc, { number: i, text: e.target.value }) }}
                                    />
                                    <span className={s.remove_question} onClick={() => removeQuestion(iLoc, i)}>-</span>

                                </div>
                            </>

                        })}
                        <tr />
                        <div onClick={() => addQuestion(iLoc)} className={'btn_update'}>Добавить критерий</div>
                        <div onClick={() => addStandatrQuestions(iLoc)} className={'btn_update'}>Добавить стандарные критерии</div>
                        <br />
                        <div onClick={() => removeLocation(iLoc)} className={'btn_remove ' + s.btn_remove}>Удалить этап</div>
                    </div>
                </div>) :
                <div>Нет этапов</div>
            }
            <div>
                <div onClick={addLocation} className={'btn_add'}>Добавить этап</div>
            </div>

            {isViewMessage ? <div className={s.next_menu}>{message}</div> : <></>}
            <div onClick={async () => {
                const result = await save()
                if (result.warning)
                    setMessage(result.message ? result.message : 'Ошибка сервера')
                else
                    setMessage('Опросник сохранен')
                setIsViewMessage(true)
                setTimeout(() => {
                    setIsViewMessage(false)
                    setMessage('')
                }, 1200)
            }} className={'btn_save'}>Сохранить</div>
            <div onClick={close} className={'btn_remove'}>Закрыть</div>

        </div>
    );
};

export default RedactGameFrom;