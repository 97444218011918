import React, {useState} from 'react';
import s from './AuthPage.module.css'
import AuthState from "../../State/AuthState/AuthState";
import patternImg from "../../resources/stick.png";

const AuthPage = ({qrLogin,qrPassword}) => {

    const [login, setLogin] = useState(qrLogin?qrLogin:"")
    const [password, setPassword] = useState(qrPassword?qrPassword:"")
    const [errorMessage, setErrorMessage] = useState("")
    const [isError, setIsError] = useState(false)

    const authorization = async ()=>{
        const result = await AuthState.login(login, password)
        if (result.warning){
            setErrorMessage(result.message)
            setIsError(true)
        }

    }

    if (qrLogin && qrPassword){
        authorization()

    }

    return (
        <div className={s.wrapper}>
            <h2 className={s.title}> Страница авторизации</h2>
            <div className={s.text_box_wrapper}>
                <input className={s.text_box}
                       type="text"
                       placeholder={'логин'}
                       value={login}
                       onChange={(e)=>{
                           setLogin(e.target.value)
                       }}
                />
                <input className={s.text_box}
                       type="text"
                       value={password}
                       onChange={(e)=>{
                           setPassword(e.target.value)
                       }}
                       placeholder={'пароль'}/>
                { isError && errorMessage ?
                <div className={s.error_row}>{errorMessage}</div>:
                <div className={s.error_row_empty}/>
                }
               <div onClick={
                   async ()=>{
                       if (login && password)
                            await authorization()
                       else{
                           setIsError(true)
                           setErrorMessage('Пользователь или пароль не может быть пустым')
                       }

                   }} className={'btn_update'}>Подтвердить</div>
            </div>

        </div>
    );
};

export default AuthPage;