import React, {useState} from 'react';
import s from './FormRedactUser.module.css'

const FormRedactUser = ({user, closeForm, redactUserField, saveUser}) => {
    const isNewUser = !user.id
    const [isMessageView, setIsMessageView] = useState(false)
    const [message, setMessage] = useState('')
    return (
        <div className={s.wrapper}>
            {isNewUser?
                <div className={s.title}>Создание пользователя</div>:
                <div  className={s.title}>Редактирование пользователя</div>

            }

            <div>
                <div className={s.wrapper_input}>
                    <div className={s.title_field}>Логин</div>
                    <input  placeholder={'логин'} onChange={(e)=>{
                        redactUserField('username',e.target.value)
                    }} type="text" value={user.username}
                    />

                </div>

                {/*{isNewUser?*/}
                    <div className={s.wrapper_input}>
                        <div className={s.title_field}>Пароль</div>
                        <input placeholder={'пароль'} onChange={(e)=>{
                            redactUserField('password', e.target.value)
                        }} type="text" value={user.password}
                        />
                    </div>
                {/*:*/}
                {/*    <></>*/}
                {/*}*/}
                <div className={s.wrapper_input}>
                    <div className={s.title_field}>Имя пользователя</div>
                    <input  placeholder={'string name'} onChange={(e)=>{
                        redactUserField('stringName',e.target.value)
                    }} type="text" value={user.stringName}
                    />

                </div>
                {/*<div className={s.wrapper_input}>*/}
                {/*    <div className={s.title_field}>Роль</div>*/}
                {/*    <select className={s.select_type_user} name="type" value={user.type} defaultValue={'user'} onChange={(e)=>redactUserField('role',e.target.value)}>*/}
                {/*        <option value={'user'}>игротехник</option>*/}
                {/*        <option value={'admin'}>администратор</option>*/}
                {/*    </select>*/}
                {/*    /!*<input onChange={(e)=>{*!/*/}
                {/*    /!*    redactUserField('role',e.target.value)*!/*/}
                {/*    /!*}} type="text" value={user.role} />*!/*/}
                {/*</div>*/}



            </div>
            <div className={'btn_save'} onClick={async ()=> {
                const res = await saveUser(isNewUser ? 'create' : 'update')
                if (res.warning)
                    setMessage(res.message?res.message:"Ошибка сервера")
                else
                    setMessage('Пользователь сохранен')

                setIsMessageView(true)
            }}>Сохранить</div>
            <div className={'btn_remove'} onClick={closeForm}>Закрыть</div>
            <div className={s.message_user_report}>{isMessageView?message:''}</div>
        </div>
    );
};

export default FormRedactUser;