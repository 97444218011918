import responseService from "../../Services/responseService";
import config from "../../config";
import GameClass from "./GameClass";

class GameState{


    async getAllLGame(){
        const result = await responseService.responseApiServer(config.URL_GET_GAMES)
        return result
    }

    async getGame(gameId, location){
        const result = await responseService.responseApiServer(config.URL_GET_GAME, {id:gameId, location})
        return result
    }

    async removeGame(gameId){
        const result = await responseService.responseApiServer(config.URL_REMOVE_GAME, {id:gameId})
        return result
    }

    async createGame(game){
        const result = await responseService.responseApiServer(config.URL_CREATE_GAME, {game})
        return result
    }

    async updateGame(game){
        const result = await responseService.responseApiServer(config.URL_UPDATE_GAME, {game})
        return result
    }

    getEmptyGame(){
        return new GameClass()
    }


}

export default new GameState()