import React, {useState} from 'react';
import s from './GameCard.module.css'
import QRCode from 'qrcode.react';

const GameCard = ({game, redact, remove}) => {
    const [isDel, setIsDel]= useState(false)
    console.log(`${window.location.origin}/?grade=${game.id}`)
    const downloadQRCode = (game) => {
        const qrCodeURL = document.getElementById('qrCodeEl_'+game.id)
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = `${game.name}_QR.png`
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);

    }
    return (
        <div className={s.wrapper}>
            <div className={s.name}>{game.name}</div>
            <div>
                <span>Количество этапов: {game.locations.length}</span>

                    <div className={s.qr_wrapper}>
                    <QRCode
                        key={"qrCodeEl_"+game.id}
                        id={"qrCodeEl_"+game.id}
                        size={100}
                        value={`${window.location.origin}/?grade=${game.id}`}
                    />
                        <div>
                            {/*<input value={`${window.location.origin}/?grade=${game.id}`}/>*/}
                        </div>

                    <div onClick={()=>downloadQRCode(game)} className={'btn_add'}>Скачать QR</div>
                </div>
            </div>
            <div>{game.description}</div>
            {   isDel?
                <div className={s.delete_text}>Игра удалена</div>:
                <>
                <div onClick={redact} className={'btn_redact'}>Редактировать</div>
                <div onClick={async ()=>{
                    if(window.confirm(`Удалить игру ${game.name}?`))
                        setIsDel(await remove(game.id))
            }} className={'btn_remove'}>Удалить</div>
                </>
                }
        </div>
    );
};

export default GameCard;