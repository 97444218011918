import React, {useEffect, useState} from 'react';
import s from './GradeUserPage.module.css'
import Loader from "../Static/Loader/Loader";
import Cookies from "js-cookie";
import config from "../../config";
import ReytingComponent from "./ReytingComponent/ReytingComponent";
import AgeAndGenderComponent from "./AgeAndGenderComponent/AgeAndGenderComponent";


const GradeUserPage = ({gameId}) => {
    const [isLoad, setIsLoad] = useState(false)
    const [isError, setIsError] = useState(false)
    const [message, setMessage] = useState('')
    const [textTask, setTextTask] = useState('')
    const [selected, setSelected] = useState({});
    const [ws,setWs] = useState(null)
    const [isEmptyGrade, setIsEmptyGrade] = useState(false)
    const [competencies , setCompetencies] = useState([])
    const [isAnswer, setIsAnswer] = useState(false)
    const [isAnswerLast, setIsAnswerLast] = useState(false)
    // const [questionNumber, setQuestionNumber] = useState(-1)
    const [isUserInfo, setIsUserInfo] = useState(false)

    const setGrade = (id, grade)=>{
        const newGrades = {...selected}
        newGrades[id] = grade
        setSelected(newGrades)
    }

    const sendGrade = ()=>{
        let isAllGradeSet = true
        for(let i=0; i<competencies.length; i++){
            if(!selected[i]) {
                isAllGradeSet = false
                break
            }
        }
        if(isAllGradeSet){
            ws.send(JSON.stringify({action:'putGrade', grade:selected}))
            setSelected({})
        }else if(!isEmptyGrade){
            setIsEmptyGrade(true)
            setTimeout(()=>{ setIsEmptyGrade(false)},1200)
        }
    }

    const sendUserInfo = (age, gender)=>{
            ws.send(JSON.stringify({action:'putUserInfo',age, gender }))
    }


    const init = async ()=>{
        const ws = new WebSocket(config.SERVER_SOCKET)
        setWs(ws)
        let userId = Cookies.get('user_id')
        ws.onopen = function () {
            console.log("подключился");
            // setIsOnline(true)
            ws.send(JSON.stringify({
                action: 'login',
                userId:userId?userId:-1,
                gameId
            }));
        };
        ws.onclose = () => {
            // setIsOnline(false)
            console.log('disconnect')
            init()
        }
        ws.onmessage = function (message) {
            const data = JSON.parse(message.data)
            console.log("Message", data);

            switch (data.action) {
                case 'login':
                    if(data.warning){
                        switch (data.message) {
                            case 'bad token':
                                setMessage('Неверный QR.')
                                break
                            case 'bad game':
                                setMessage('Опрос не доступен')
                                break
                            default:
                                setMessage('Ошибка сервера')
                                break
                        }
                        setIsError(true)
                    }else{
                        if(!userId){
                            userId = data.userId
                            Cookies.set('user_id', userId, { expires: 365 })
                        }
                        if (data && data.location && data.gameName){
                            if (data.isUserInfo){
                                setIsUserInfo(true)

                            }
                            setTextTask(data.location.question)
                            setCompetencies(data.location.locations)
                            setIsAnswer(data.isAnswer)
                            if (data.isAnswer){
                                setIsAnswerLast(true)
                            }else{
                                setIsAnswerLast(false)
                            }
                            // setGameName(data.gameName

                        }else{
                            setMessage('Опрос не доступен')
                            setIsError(true)
                        }
                    }
                    break
                case 'reportPutGrade':
                    if(!data.warning)
                        setIsAnswer(true)
                        // setTimeout(()=>{
                        //     if( data.answer.length >= data.location.length){
                        //         setIsAnswerLast(true)
                        //     }else{
                        //         setSelected(-1)
                        //         setTextTask(data.location[data.answer.length])
                        //         setQuestionNumber(data.answer.length+1)
                        //         setIsAnswer(false)
                        //         setIsAnswerLast(false)
                        //     }
                        // },1200)
                    break
                case "reportUserInfo":
                    if(!data.warning)
                        setIsUserInfo(false)
                        // setTimeout(()=>{
                        //     if( data.answer.length >= data.location.length){
                        //         setIsAnswerLast(true)
                        //     }else{
                        //         setSelected(-1)
                        //         setTextTask(data.location[data.answer.length])
                        //         setQuestionNumber(data.answer.length+1)
                        //         setIsAnswer(false)
                        //         setIsAnswerLast(false)
                        //     }
                        // },1200)
                    break
            }
            if (!isLoad)
                setIsLoad(true)
        }


    }

    useEffect(()=>{
        setIsLoad(false)
        init()
    },[])

    if(isError){
        return (
            <div>{message}</div>
        )
    }

    if (isUserInfo)
    return (
        <AgeAndGenderComponent setAgeAndGender={sendUserInfo}/>
    )
    if (!isLoad){
        return <Loader/>
    }
    if (isAnswer || isAnswerLast){
        return (
            <div className={s.wrapper}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                     stroke="green"  stroke-width="3" stroke-dasharray="100">
                    <path id="check" d="M 12,22 L 22,31 L 36,13"
                          stroke-dashoffset="0">
                        <animate attributeName="stroke-dashoffset"
                                 from="100" to="0" dur="1.2s"></animate>
                    </path>
                </svg>

                    <div className={s.answer}>
                        {isAnswerLast?<>Ваш ответ принят!</>:
                            <>Спасибо,<br/>Ваш ответ принят!</>}
                    </div>
            </div>
        )
    }
    return (
        <div className={s.wrapper}>
            {/*<div className={s.game_title}>{gameName}</div>*/}
            {/*<div className={s.question_number}>Вопрос {questionNumber} из {countQuestion}</div>*/}
            <div className={s.game_text_ask}>{textTask}</div>
            {
                competencies.map((com, i)=>{
                    return(
                        <div key={com} className={s.wrapper_answer}>
                            <div className={s.compitents_text}>{com}</div>
                            <div className={'wrapper_emoji'}>
                                <div className={s.emoji}>
                                    <ReytingComponent selected={selected[i]} setSelect={setGrade} id={i} />
                                </div>
                                <div className={s.empty_grade}>{isEmptyGrade && !selected[i]?'Выберите оценку':''}</div>

                            </div>
                        </div>
                    )
                })
            }
            <div onClick={sendGrade} className={'btn_update'}>Отправить</div>


        </div>
    );
};

export default GradeUserPage;