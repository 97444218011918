import React from 'react';
import s from './ReytingComponent.module.css'

const ReytingComponent = ({selected, setSelect, id}) => {
    const res = [1,2,3,4,5]
    return (
        <div className={s.wrapper}>
            {
                res.map(r=><div key={'grade_'+id+r}
                                onClick={()=>setSelect(id,r)}
                                className={selected===r?s.empty_active:s.empty}>{r}</div>
                )
            }
        </div>
    );
};

export default ReytingComponent;