import React from 'react';
import s from './ResultModule.module.css'

const ResultModule = ({close, users, grades, locations, userInfo}) => {
    return (
        <table className={s.result_table}>
            <tbody>
            {
                locations.map((l,i)=>{
                    return(
                        <tr key={'quest'+i}>
                            <td>Этап {i+1}</td>
                            <td  className={s.table_row_wrapper}><TableRowByLocation userInfo={userInfo} locations={l} users={users} grades={grades} location={i} /></td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    )
};

const TableRowByLocation  = ({users, grades, locations,location, userInfo})=> (<>
        <table className={s.table_row}>
            <tbody>
            <tr>
                <th>Код</th>
                {locations.locations.map((loc,index)=>
                    <th key={'user_result_name'+index+loc}>
                    {loc}
                </th>)}
            </tr>

            {
                users.map((u,i)=>{
                    return(
                        <tr key={'row_result'+i}>
                            <td>{u} {userInfo[u]?'('+userInfo[u].gender:''} {userInfo[u]?userInfo[u].age+')':''} </td>
                            { locations.locations.map((loc,index)=>{

                                const userGrade = grades[u].find(g=>g.location === location)
                                return(
                                        <td  key={'user_result'+index+i}>{userGrade && userGrade.grade && userGrade.grade[index]?userGrade.grade[index]:'-'}</td>
                                )
                        })}
                        </tr>

                    )

                })
            }

            </tbody>
        </table>

    </>
);

export default ResultModule;