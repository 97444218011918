import React, {useEffect, useState} from 'react';
import s from "./GradePage.module.css";
import playSvg from "../../../../resources/exit.svg";
import config from "../../../../config";
import Cookies from "js-cookie";
import Loader from "../../../Static/Loader/Loader";
import ResultModule from "../ResultModule/ResultModule";
import ExcelExports from "../../../../Services/excelExports";

const GradePage = ({close, gameId}) => {
    const [isLoad, setIsLoad] = useState(false)
    const [isError, setIsError] = useState(false)
    const [message, setMessage] = useState('')
    const [ws,setWs] = useState(null)
    const [isOnline, setIsOnline] = useState(false)
    const [isViewResult, setIsViewResult] = useState(false)
    const [game, setGame] = useState('')
    const [grades, setGrades] = useState(null)
    const [users, setUsers] = useState([])
    const [userInfo,setUserInfo] = useState({})

    const createExcelData = ()=>{
        const excelData = []

            users.forEach((u,i)=>{
                if (userInfo[u])
                    excelData.push({id:u, пол:userInfo[u].gender, возраст:userInfo[u].age})
                    else
                excelData.push({id:u})
                game.locations.forEach((location,localIndex)=>{
                    location.locations.forEach((l,index)=>{
                        const gradeUser = grades[u].find(g=>g.location === localIndex)
                        excelData[i][ game.locations.length>1?localIndex+1+'. '+l:l]= gradeUser?gradeUser.grade[index]:'-'
                    })
            })
        })
        // console.log('excelData',excelData)

        return excelData
    }

    const removeGrade = async (gameId)=>{
        ws.send(JSON.stringify({action:'removeProgress',gameId}))
    }


    const setAsk = (gameId, numberLocation)=>{
        ws.send(JSON.stringify({action:'nextAnswer',gameId, numberLocation}))
    }

    const setUsersList = (gradesList)=>{
        const usersList = []
        for(let u in gradesList)
            usersList.push(u)
        setUsers(usersList)
    }

    const init = async ()=>{
        const ws = new WebSocket(config.SERVER_SOCKET)
        setWs(ws)
        const token = Cookies.get('token')
        ws.onopen = function () {
            console.log("подключился");
            setIsOnline(true)
            ws.send(JSON.stringify({
                action: 'login',
                token,
                gameId
            }));
        };
        ws.onclose = () => {
            setIsOnline(false)
            console.log('disconnect')
            init()
        }
        ws.onmessage = function (message) {
            const data = JSON.parse(message.data)
            // console.log("Message", data);

            switch (data.action) {
                case 'login':
                    if(data.warning){
                        switch (data.message) {
                            case 'bad token':
                                setMessage('Неверный QR.')
                                break
                            case 'bad game':
                                setMessage('Опрос не доступен')
                                break
                            default:
                                setMessage('Ошибка сервера')
                                break
                        }
                        setIsError(true)
                    }else{
                        if (data.game){
                            if (data.userInfo){
                                setUserInfo(data.userInfo)
                            }
                            if(data.grades) {
                                setUsersList(data.grades.grades)
                                setGrades(data.grades.grades)
                            }
                            setGame(data.game)
                        }else{
                            setMessage('Опрос не доступен')
                            setIsError(true)
                        }
                    }
                    break
                case 'reportPutGrade':
                    if(!data.warning){
                        if(data.grades) {
                            setUsersList(data.grades.grades)
                            setGrades(data.grades.grades)
                        }
                    }
                    break
            }

                setIsLoad(true)
        }


    }

    useEffect(()=>{
        setIsLoad(false)
        init()
    },[])

    if(isError){
        return (
            <div>{message}</div>
        )
    }

    if (!isLoad){
        return <Loader/>
    }
    return (
        <div>
            <div className={s.icon_wrapper}>
                <img onClick={close} className={s.setting_play} src={playSvg} alt=""/>
            </div>
            <div className={s.wrapepr_game}>
                <div>{game.name}</div>
                <div>Текущий этап: {game.process?game.process.location+1:'нет'}</div>
            </div>

            <div className={s.wrapper_answers}>
                <div>Список вопросов:</div>
                {game.locations?game.locations.map((location, index)=>{
                    const locationGrade = []
                    let count = 0
                    users.forEach(u=> {
                        count++
                        grades[u].forEach(g=>{
                            if(g.location === index)
                                locationGrade.push({user:u, grade:g.grade})
                        })
                    })
                    return (
                        <div key={'text_answer_'+index} className={index===game.process.location?s.active_location:s.location}>
                            <div>
                                <div className={s.number_location}>Этап {index+1}</div>
                                <div className={s.number_location}>Вопрос:</div>
                                <div className={s.question_text}>{location.question}</div>
                                <div className={s.number_location}>Критерии</div>
                                {location.locations.map((l,i)=><div key={'loc'+i} className={s.wrapper_question}>{i+1}. {l}</div>)}</div>
                            <div>Ответов: {locationGrade.length}</div>
                        </div>)
                }):'нет вопросов'}
            </div>
            <div className={s.wrapper_btn}>
                <div onClick={()=> {
                    if (game.process.location+1 < game.locations.length)
                        setAsk(game.id, game.process.location + 1)
                    }
                }
                     className={game.process.location+1 < game.locations.length?'btn_save':'btn_save_no_active'}>Следующий вопрос</div>
                <div onClick={()=>setIsViewResult(!isViewResult)} className={'btn_update'}>Результаты</div>
                {/*<ExcelExports exportData={multiDataSet} fileName={'test'} />*/}
                <ExcelExports exportData={createExcelData()} fileName={game.name} />
            </div>

            <div className={s.wrapper_btn}>
                <div onClick={()=>{
                    if (game.process.location>0)
                        setAsk(game.id,game.process.location-1)
                }} className={game.process.location>0?'btn_remove':'btn_remove_no_active'}>Предыдущий вопрос</div>
                <div onClick={async (e)=>{
                    if (window.confirm('Удалить все результаты опроса?')){
                       await removeGrade(gameId)
                    }
                }} className={'btn_remove'}>Удалить результаты</div>
            </div>

            {isViewResult?
                <ResultModule close={()=>setIsViewResult(false)}
                              grades={grades}
                              users={users}
                              userInfo={userInfo}
                              locations={game.locations}
                />:
                <></>
            }
        </div>
    );
};

export default GradePage;