import React from 'react';
import s from './ConstructorNavComponent.module.css'

const ConstructorNavComponent = ({constructorPage, setConstructorPage, constructorNavList}) => {
    return (
        <div className={s.wrapper}>
            {
                constructorNavList.map((p, index)=><div onClick={()=>setConstructorPage(p.page)}
                                                        className={constructorPage===p.page?s.item_nav_active:s.item_nav}
                                                        key={ 'constructor_nav_'+index }>{p.name}</div>)
            }
        </div>
    );
};

export default ConstructorNavComponent;