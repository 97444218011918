import React from 'react';
import s from './NavbarAdmin.module.css'

const NavbarAdmin = ({page, setPage, navList}) => {
    return (
        <div className={s.wrapper}>
            NavbarAdmin
            <div className={s.wrapper_nav}>
                {
                    navList.map((p, index)=> <div className={s.item_nav}
                                                  key={"nav_admin_"+index}
                                                  onClick={()=>{setPage(p.page)}}
                                            >{p.name}</div>)
                }
            </div>
        </div>
    );
};

export default NavbarAdmin;