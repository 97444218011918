import React, {useEffect, useState} from 'react';
import config from "../../config";
import Cookies from "js-cookie";
import AdminPlacePage from "../AdminPage/AdminPlacePage";
import AdminGamePage from "./AdminGamePage/AdminGamePage";
import s from './GamePage.module.css'
import Loader from "../Static/Loader/Loader";
import settingSvg from '../../resources/settings.svg'
import GameState from "../../State/GameState/GameState";
import GameInfo from "./AdminGamePage/GameInfo/GameInfo";
import GradePage from "./AdminGamePage/GradePage/GradePage";

const GamePage = () => {
    const [isLoad,setIsLoad] = useState(false)
    const [isSetting, setIsSetting] = useState(false)
    const [games, setGames] = useState([])
    const [activeGame, setActiveGame] = useState(null)

    const init =async ()=>{
        const result = await GameState.getAllLGame()
        if(result.warning){
            // setErrorMessage(result.message)
            // setIsError(true)
        }else {
            setGames(result.data.games)
        }

      setIsLoad(true)
    }
    useEffect(()=>{
        const token = Cookies.get('token')
        const  reLoad = ()=>{
            Cookies.remove('token')
            window.location = window.location.origin
        }

        if (!token){
            reLoad()
        }
        init()
    },[])


    if(!isLoad){
        return <Loader/>
    }//return loader
    if(isSetting) {
        return (
            <AdminGamePage close={()=>setIsSetting(false)}/>
        )
    }//return settings
    if (activeGame){
        return (
            <GradePage gameId={activeGame.id} close={()=>{setActiveGame(null)}}/>
        )
    }
    return (
        <>
            <img onClick={()=>setIsSetting(true)} className={s.setting_icon} src={settingSvg} alt=""/>
            {
                games&&games.length>0? games.map((game,i)=>{
                        return(
                            <GameInfo key={'game_list_'+i} startGame={()=>{setActiveGame(game)}} game={game} />
                        )
                    }

                ):
                    <div className={s.not_games}>Нет опросников</div>
            }
        </>

    )
};

export default GamePage;