import React, {useEffect, useState} from 'react';
import userState from "../../../../../State/UserState/UserState";
import UserCardComponent from "./UserCardComponent/UserCardComponent";
import s from './RedactUserComponent.module.css'
import FormRedactUser from "./FormRedactUser/FormRedactUser";
import UserState from "../../../../../State/UserState/UserState";

const RedactUserComponent = () => {
    const [users, setUsers] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [redactUser, setRedactUser] = useState(null)
    const [formRedactIsActive, setFormRedactIsActive] = useState(false)

    const closeForm = ()=>setFormRedactIsActive(false)
    const newUser = ()=>{
        setRedactUser(userState.getEmptyUser())
        setFormRedactIsActive(true)
    }
    const startRedactUser = (user)=>{
        setRedactUser(user)
        setFormRedactIsActive(true)
    }

    const removeUser = async (userId) =>{
        const result = await userState.removeUser(userId)
        const newUsersList = []

        if(result.warning){
            alert(result.message)
        }
        else{
            users.forEach(u=> {
                if (u.id === userId)
                    u.isDelete = true
                newUsersList.push(u)

            })
            setUsers(newUsersList)
        }
    }
    const saveUser = async (operationType)=>{
        let result

        if(operationType === 'update'){
            result = await UserState.updateUser(redactUser)
            if (!result.warning){
                const newUserList = []
                users.forEach(u=>{
                    if(u.id === redactUser.id)
                        newUserList.push({...redactUser})
                    else
                        newUserList.push(u)
                })
                setUsers(newUserList)
            }
        }else{
            result = await UserState.createUser(redactUser)
            if(!result.warning) {
                const newUserList = [...users]
                newUserList.push({...result.data.user})
                setUsers(newUserList)
            }
        }

        return result
    }
    const changePassword = (userId, password)=>{
        const result = UserState.changePassword(userId, password)
    }
    const redactUserField = (fieldName, value)=>{
        const user = {...redactUser}
        user[fieldName] = value
        setRedactUser(user)
    }

    const init = async ()=>{
        setIsLoad(false)
        const result = await userState.getAllUsers()
        setUsers(result.data.users)
        setIsLoad(true)
    }

    useEffect(()=>{
        init()
    },[])

    if (formRedactIsActive)
        return (
            <>
                <FormRedactUser user={redactUser} saveUser={saveUser} redactUserField={redactUserField} closeForm={closeForm}/>
            </>
        )
    else
    return (
        <div>
            {isLoad?
                <div>
                    {users.length === 0?
                        <div className={s.title}>Список пользователей</div>:
                        <div>
                            <div className={s.title}>Список пользователей</div>
                            {
                            users.map(user=>
                                <UserCardComponent key={'cards_users'+user.id} changePassword={changePassword}
                                                   startRedactUser={startRedactUser} removeUser={removeUser}
                                                   user={user}/>
                            )
                        }</div>
                    }
                   <div className={s.btn_wrapper}>
                       <div onClick={newUser} className={'btn_add'}>Добавить пользователя</div>
                   </div>
                </div>:
                <div></div>
            }
        </div>
    );
};

export default RedactUserComponent;